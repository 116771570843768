.terms-page {
  padding: 0 15px;

  h1 {
    margin-bottom: 40px;
  }

  p {
    font-size: 1rem;
    line-height: 1.7;
    margin-bottom: 20px;
  }
}
@use './fonts.scss';

.footer-2 {
  background-color: #F8F9FA;

  .container {
    max-width: 1140px;
    padding: 0 15px;
  }

  .content {
    max-width: 948px;
    margin: 0 auto;
  }

  &__top {

    .container {
      border-bottom: 1px solid #E7E7E7;
    }

    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px 0 40px;

      .logo {
        position: absolute;
        top: 36px;
        left: 0;

        img {
          display: block;
          max-width: 100%;
        }
      }

      ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {

          a {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: #343A40;
            padding: 10px 0;
            display: block;
            margin: 0 19px;
            text-decoration: none;
            transition: color .3s ease;

            &:hover {
              color: #2D00F7;
              text-decoration: underline;
            }
          }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
        padding: 40px 0;

        .logo {
          position: static;
          margin-bottom: 30px;
        }
      }
    }
  }

  &__bottom {
    padding: 36px 0 88px;

    .box {
      background-color: #B8B4B4;
      padding: 14px 22px;
      box-shadow: 0 2px 4px 0 rgba(220,220,220,0.5);
      border-radius: 6px;
      margin-bottom: 46px;
      font-family: "Akira Expanded";
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
      }

      .brand-logo {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }

        img {
          display: block;
          margin-right: 12px;
          width: 28px;
          height: 28px;
        }

        p {
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.9px;
          font-weight: bold;
        }
      }

      > p {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.7px;
        font-weight: normal;

        @media (max-width: 767px) {
          text-align: center;
        }

        a {
          color: #494949;
          text-decoration: none;
          font-weight: bold;
          transition: color .3s ease;

          &:hover {
            text-decoration: underline;
            color: #2D00F7;
          }
        }
      }
    }

    .copyright {
          
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.4px;
        color: #888C8F;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        a {
          color: inherit;
          text-decoration: none;
          transition: color .3s ease;

          &:hover {
            color: #2D00F7;
            text-decoration: underline;
          }
        }

        .divider {
          display: block;
          margin: 0 3px;
        }
      }
    }
  }
}
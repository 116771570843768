.sec-wrap--beci {

  h1,
  h2,
  .hero__h,
  .sec__h2 {
    color: #2800d7;
    font-family: 'Nunito', sans-serif;
  }
  
  .hero__h span {
    color: #2800d7 !important;
  }
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  .prev {
    margin-right: 10px;
  }
}

.sec-wrap--affinicia {

  .navbar-logo--affinicia {
    max-height: 50px;
    max-width: 108px;
  }
}
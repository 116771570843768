.sec-wrap--beci .sticky-collapse {
  top: 122px;
}

.header-top,
.header-bottom {

  @media (max-width: 991px) {
    .container {
      padding: 0 15px;
    }
  }

  @media (max-width: 480px) {
    .container {
      padding: 0 5px;
    }
  }
}

.header-top {
  padding: 4px 0;
  border-bottom: 1px solid #dee2e6;
  background-color: #2800d7;

  &_inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .languages {
    display: flex;
    align-items: center;

    .nav-link--lang {
      color: #fff;
      font-size: 1rem;
      line-height: 1.5;
      padding: 0 1rem;
      height: 40px;
      margin: 0;
      display: flex;
      align-items: center;
      opacity: 1;

      &:hover,
      &.highlighted {
        background-color: #1f00a4;
        color: #fff;
      }
    }
  }

  .social,
  .contacts {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .social {

    a {
      padding: 0 0.5rem;
      color: #fff;
      display: flex;
      align-items: center;
      height: 40px;

      svg {
        font-size: 1.25rem;
        display: block;
      }

      &:hover {
        background-color: #1f00a4;
      }
    }
  }

  .contacts {

    a {
      padding: 0 0.75rem;
      height: 40px;
      font-size: 1rem;
      line-height: 1.5;
      color: #fff;
      text-decoration: none;
      display: flex;
      align-items: center;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      svg {
        font-size: 1.25rem;
        margin-right: 2px;
      }

      &:hover {
        background-color: #1f00a4;
      }

      @media (max-width: 991px) {
        span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

.header-bottom {
  position: sticky;
  top: 0;
  z-index: 1001;
  background-color: #2800d7;

  .w-nav {
    background-color: #2800d7;

    .nav-menu {
      margin-bottom: 0;
      background-color: #2800d7;

      @media (max-width: 991px) {
        padding: 10px 20px;
      }
    }

    .nav-link-wrap {
      padding-left: 0;
      padding-right: 0;

      .nav-link {
        color: #fff;
        height: 122px;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        margin: 0;

        @media (max-width: 991px) {
          height: 40px;
          width: 100%;
        }

        &:hover {
          background-color: #1f00a4;
        }

      }

      .nav-line {
        display: none !important;
      }
    }

    .brand-link {
      height: 122px;
    }

    .navbar-logo {
      height: 110px;
    }

    .nav-btn {
      color: #f8f9fa;
      border-color: #f8f9fa;
      background-color: transparent;

      &:hover {
        background-color: #f8f9fa;
        border-color: #f8f9fa !important;
        
        h4 {
          color: #212529 !important;
        }
      }

      .growing-div {
        display: none;
      }
    }

    @media (max-width: 991px) {
      .w-nav-button {
        position: relative !important;
        width: 56px;
        height: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 1px solid rgba(255,255,255,0.1);
      }

      .nav__g-line {
        display: none;
      }
    }
  }
}

.flip-card {
  background-color: transparent;
  width: 110px;
  height: 110px;
  perspective: 1000px; 
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: #2800d7;
  color: white;
  transform: rotateY(180deg);

  p {
    font-size: 0.875rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}